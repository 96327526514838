function initiateScrollToTop($) {
    $('body').prepend('<a id="top"></a>');
    $('body').append('<a href="#top" id="scrollToTop"><span aria-hidden="true">&uarr;</span><span class="sr-only">Go back to top</span></a>');
    $(window).scroll(function() {
      if ($(window).scrollTop() > 150) {
          $("#scrollToTop").show();
      } else {
          $("#scrollToTop").hide();
      }
    });
    $(window).trigger('scroll');

    $("#scrollToTop").click(function(e) {
      e.preventDefault();
      $('html,body').animate({ scrollTop: 0 }, 'slow');
    });
}
