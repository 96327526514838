
function updateApartments(jQuery, apartmentsData) {
  var originalLabel = jQuery('#objectId').find('option').first().text();
  jQuery('#objectId').children('option').remove();
  jQuery('#objectId').attr('disabled', 'disabled')
    .prepend('<option selected>--- Laddar... ---</option>');

  //TODO Fix global jQuery
  jQuery.ajax({
    type: 'GET',
    url: apartmentsData.ajax_url,
    dataType: 'json',
    timeout: 300000,
    data: {
      action: 'get_apartments',
      floorId: apartmentsData.floorId
    },
    success: function(data) {
      if(data) {
        jQuery('#objectId').children('option').remove();
        jQuery('#objectId').removeAttr('disabled')
          .prepend('<option selected>'+ originalLabel +'</option>')
          .append(data);
      }
    },
    error: function(jqXHR, textStatus, errorThrown) {
      jQuery('#objectId').next('.field-validation').removeAttr('hidden').text('Kunde inte hitta lägenheter för denna adress.');
      console.warn('Could not get posts, server response: ' + textStatus + ': ' + errorThrown);
    },
  });
}

function sendServiceForm(jQuery, formData) {
  jQuery('#btnSend').attr('disabled', 'disabled');
  jQuery('#btnSend').text('Skickar...');

  //TODO Fix global jQuery
  jQuery.ajax({
    type: 'GET',
    url: apartmentsData.ajax_url,
    dataType: 'json',
    timeout: 300000,
    data: {
      action: 'send_serviceform',
      formData: formData
    },
    success: function(data) {
      if(data && data.Success) {
        jQuery('#serviceform').slideUp();
        jQuery('.thank-you').removeAttr('hidden');
      }
      else {
        jQuery('#btnSend').removeAttr('disabled');
        jQuery('#btnSend').text('Försök igen');
        jQuery('.form-validation').text('Något gick fel, kontrollera alla fält och försök igen.');

        console.warn('API response was bad', data);
      }

    },
    error: function(jqXHR, textStatus, errorThrown) {
      jQuery('#btnSend').removeAttr('disabled');
      jQuery('#btnSend').text('Försök igen');
      jQuery('.form-validation').removeAttr('hidden').text('Något gick fel, kontrollera alla fält och försök igen.');
      console.warn('Could not get posts, server response: ' + textStatus + ': ' + errorThrown);
    },
  });
}
function initiateServiceForm($) {
  var addressOriginalLabel = jQuery('#address').find('option').first().text();
  var accessObjectOriginalLabel = jQuery('#accessObject').find('option').first().text();

  jQuery('#address').attr('disabled', 'disabled')
    .html("")
    .prepend('<option>--- Laddar... ---</option>');

  jQuery('#accessObject').attr('disabled', 'disabled')
    .html("")
    .prepend('<option>--- Laddar... ---</option>');

  if (typeof street_addresses === 'undefined') {
    return;
  }

  if($('.serviceform').length > 0) {

    $.ajax({
      type: 'GET',
      url: street_addresses.ajax_url,
      dataType: 'json',
      timeout: 300000,
      data: {
        action: 'get_options'
      },
      success: function(data) {
        if(data.addresses) {
          jQuery('#address').removeAttr('disabled')
            .html("")
            .prepend('<option>'+ addressOriginalLabel +'</option>')
            .append(data.addresses);
        }

        if(data.accessObjects) {
          jQuery('#accessObject').removeAttr('disabled')
            .html("")
            .prepend('<option>'+ accessObjectOriginalLabel +'</option>')
            .append(data.accessObjects);
        }

        $('.serviceform').find('#address').change(function () {
          apartmentsData = {
            'ajax_url': street_addresses.ajax_url,
            'floorId': $(this).val()
          };
          updateApartments($, apartmentsData);
        });

        $('.serviceform').find('#serviceform').submit(function (e) {
          e.preventDefault();

          var formData = {
            "objectId":     jQuery('#objectId').val(),
            "accessObject": jQuery('#accessObject').val(),
            "description":  jQuery('#description').val(),
            "name":         jQuery('#name').val(),
            "reporterName": jQuery('#reporterName').val(),
            "email":        jQuery('#email').val(),
            "phoneNumber":  jQuery('#phoneNumber').val()
          };
          sendServiceForm($, formData);
        });

      },
      error: function(jqXHR, textStatus, errorThrown) {
        $('body').removeClass('loading');
        //displayPageError(__('Something went wrong. Please try again.', bookingObject.translations));
        console.warn('Could not get posts, server response: ' + textStatus + ': ' + errorThrown);
      },
    });
  }
}


